<template>
  <layout :title="$route.name">
    <div class="expande-horizontal wrap">
      <ProductItem
        v-for="product in getProducts"
        :key="product._id"
        :product="product"
      />
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductItem from "./components/ProductItem";
export default {
  components: {
    ProductItem
  },
  computed: {
    ...mapGetters(["getProducts"])
  },
  methods: {
    ...mapActions(["listProducts"])
  },
  created() {
    this.listProducts();
  }
};
</script>
